import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Container,
  MenuItem,
  Box,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Collapse,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Menu,
  FormControl,
  InputLabel,
  Select,
} from '@mui/material';
import { PlayArrow, Pause, Add, Watch, Edit, Delete } from '@mui/icons-material';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ArrowDownward, ArrowUpward } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import {API_URL, USERS} from "./constants";



function  TaskManager({ token, handleLogout }) {
  const [tasks, setTasks] = useState([]);
  const [expandedTask, setExpandedTask] = useState(null); // Track which task is expanded
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });
  const [projects, setProjects] = useState([]);
  const [taskName, setTaskName] = useState('');
  const [assignee, setAssignee] = useState('');
  const [reporter, setReporter] = useState('');
  const [description, setDescription] = useState('');
  const [projectName, setProjectName] = useState('');
  const [projectContact, setProjectContact] = useState('');
  const [projectId, setProjectId] = useState('');
  const [statusFilter, setStatusFilter] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [view, setView] = useState('tasks');
  const [anchorEl, setAnchorEl] = useState(null);
  const [openTaskDialog, setOpenTaskDialog] = useState(false);
  const [openProjectDialog, setOpenProjectDialog] = useState(false);
  const [hourlyRate, setHourlyRate] = useState('');
  const [editedProjects, setEditedProjects] = useState({});
  const [openEditTaskDialog, setOpenEditTaskDialog] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null); // Store the task you are editing
  const [projectFilter, setProjectFilter] = useState(''); 
  const [openEditTimeIntervalLogDialog, setOpenEditTimeIntervalLogDialog] = useState(false);
  const [openEditDirectLogDialog, setOpenEditDirectLogDialog] = useState(false);
  const [logToEdit, setLogToEdit] = useState(null);
  const [currentTaskId, setCurrentTaskId] = useState(null);

    // Export logs to Excel
    const exportToExcel = () => {
      const exportData = tasks.flatMap((task) => {
        const project = projects.find((proj) => proj.id === task.project_id);
        const hourlyRate = project?.hourly_rate || 0;
  
        // Create an array for both direct logs and time interval logs
        const logs = [
          ...task.time_interval_logs.map((log) => ({
            subTask: log.description || 'No description',
            timeSpent: getTimeDifferenceInHours(log.start_time, log.end_time || new Date()), // Time spent in hours
            price: getTimeDifferenceInHours(log.start_time, log.end_time || new Date()) * hourlyRate, // Calculate price
            projectName: project?.name || 'Unknown Project',
            taskName: task.name,
          })),
          ...task.direct_logs.map((log) => ({
            subTask: log.description,
            timeSpent: log.minutes_logged / 60, // Convert minutes to hours
            price: (log.minutes_logged / 60) * hourlyRate, // Calculate price
            projectName: project?.name || 'Unknown Project',
            taskName: task.name,
          })),
        ];
  
        return logs;
      });
  
      const totalHours = exportData.reduce((sum, log) => sum + log.timeSpent, 0);
      const totalPrice = exportData.reduce((sum, log) => sum + log.price, 0);
  
      // Add total hours and total price row at the end
      exportData.push({
        subTask: 'Total',
        timeSpent: totalHours,
        price: totalPrice,
        projectName: '',
        taskName: '',
      });
  
      // Create a worksheet and workbook
      const worksheet = XLSX.utils.json_to_sheet(exportData, {
        header: ['projectName', 'taskName', 'subTask', 'timeSpent', 'price'],
      });
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, 'Logs');
  
      // Download the Excel file
      XLSX.writeFile(workbook, 'task_logs.xlsx');
    };

    // Time Interval Logs
    const handleEditTimeIntervalLog = (taskId, log) => {
      setCurrentTaskId(taskId);
      setLogToEdit(log);
      setOpenEditTimeIntervalLogDialog(true);
    };
  
    const handleDeleteTimeIntervalLog = async (taskId, logId) => {
      if (window.confirm('Are you sure you want to delete this time interval log?')) {
        try {
          await axios.delete(`${API_URL}/tasks/${taskId}/time_interval_logs/${logId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchData();
        } catch (err) {
          setError('Error deleting time interval log');
        }
      }
    };
  
    // Direct Logs
    const handleEditDirectLog = (taskId, log) => {
      console.log('Editing Direct Log:', log); // Inspect log.id here
      if (!log.id) {
        setError('Direct log ID is missing.');
        return;
      }
      setCurrentTaskId(taskId);
      setLogToEdit(log);
      setOpenEditDirectLogDialog(true);
    };
  
    const handleDeleteDirectLog = async (taskId, logId) => {
      if (window.confirm('Are you sure you want to delete this direct log?')) {
        try {
          await axios.delete(`${API_URL}/tasks/${taskId}/direct_logs/${logId}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          fetchData();
        } catch (err) {
          setError('Error deleting direct log');
        }
      }
    };
  
    // Update Functions
  
    const updateTimeIntervalLog = async () => {
      try {
        await axios.put(
          `${API_URL}/tasks/${currentTaskId}/time_interval_logs/${logToEdit.id}`,
          {
            start_time: new Date(logToEdit.start_time),
            end_time: logToEdit.end_time ? new Date(logToEdit.end_time) : null,
            description: logToEdit.description,
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOpenEditTimeIntervalLogDialog(false);
        setLogToEdit(null);
        setCurrentTaskId(null);
        fetchData();
      } catch (err) {
        setError('Error updating time interval log');
      }
    };
  
    const updateDirectLog = async () => {
      try {
        await axios.put(
          `${API_URL}/tasks/${currentTaskId}/direct_logs/${logToEdit.id}`,
          {
            description: logToEdit.description,
            minutes_logged: parseFloat(logToEdit.minutes_logged),
          },
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setOpenEditDirectLogDialog(false);
        setLogToEdit(null);
        setCurrentTaskId(null);
        fetchData();
      } catch (err) {
        setError('Error updating direct log');
      }
    };


    // Function to sort tasks
    const sortTasks = (key) => {
        let direction = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
        }

        const sortedTasks = [...tasks].sort((a, b) => {
        if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
        if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
        return 0;
        });

        setTasks(sortedTasks);
        setSortConfig({ key, direction });
    };

  const handleExpandClick = (taskId) => {
    setExpandedTask(expandedTask === taskId ? null : taskId); // Toggle task expansion
  };

  // Fetch tasks and projects from the API
  const fetchData = async () => {
    try {
        setLoading(true);
        const [taskResponse, projectResponse] = await Promise.all([
            axios.get(`${API_URL}/tasks/`, { headers: { Authorization: `Bearer ${token}` } }),
            axios.get(`${API_URL}/projects/`, { headers: { Authorization: `Bearer ${token}` } }),
        ]);
        const fetchedProjects = projectResponse.data;
        const fetchedTasks = taskResponse.data.map(task => ({
            ...task,
            project_name: fetchedProjects.find(proj => proj.id === task.project_id)?.name || 'Unknown',
        }));
        setTasks(fetchedTasks);
        setProjects(fetchedProjects);
        setLoading(false);
    } catch (err) {
        setError('Error fetching data');
        setLoading(false);
    }
};

  useEffect(() => {
    fetchData();
  }, [token]);

  // Create a new project
  const createProject = async () => {
    try {
      setLoading(true);
      await axios.post(
        `${API_URL}/projects/`,
        {
          id: null,
          name: projectName,
          contact: projectContact,
          hourly_rate: hourlyRate, // Send the hourly rate
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      setProjects((prevProjects) => [
        ...prevProjects,
        { name: projectName, contact: projectContact, hourly_rate: hourlyRate },
      ]);
      setProjectName('');
      setProjectContact('');
      setHourlyRate(''); // Clear hourly rate
      setOpenProjectDialog(false);
      setLoading(false);
    } catch (err) {
      setError('Error creating project');
      setLoading(false);
    }
  };


  // Open the edit dialog
    const handleEditTask = (task) => {
        setTaskToEdit(task); // Set the task to be edited
        setOpenEditTaskDialog(true);
    };
    // Function to update the task
    const updateTask = async () => {
        try {
            setLoading(true);
            await axios.put(`${API_URL}/tasks/${taskToEdit.id}`, {
                ...taskToEdit, // Send updated task data to the server
            }, { headers: { Authorization: `Bearer ${token}` } });
            setOpenEditTaskDialog(false);
            fetchData(); // Re-fetch the tasks after updating
            setLoading(false);
        } catch (err) {
            setError('Error updating task');
            setLoading(false);
        }
    };
  // Create a new task
  const createTask = async () => {
    try {
      setLoading(true);
      const response = await axios.post(`${API_URL}/tasks/`, {
        id: null,
        project_id: projectId,
        name: taskName,
        assignee,
        reporter,
        description,
      }, { headers: { Authorization: `Bearer ${token}` } });
      setTasks((prevTasks) => [...prevTasks, { ...response.data, name: taskName, assignee, reporter, description, time_interval_logs: [], direct_logs: [] }]);
      setTaskName('');
      setAssignee('');
      setReporter('');
      setDescription('');
      fetchData(); 
      setOpenTaskDialog(false); 
      setLoading(false);
    } catch (err) {
      setError('Error creating task');
      setLoading(false);
    }
  };

  const toggleStatus = async (task, newState) => {
    try {
      setLoading(true);
  
      // Optimistically update the task status in local state before the PUT request completes
      setTasks((prevTasks) =>
        prevTasks.map((t) =>
          t.id === task.id ? { ...t, state: newState } : t // Update the task status in local state
        )
      );
  
      // Make the PUT request to update the task in the backend
      await axios.put(`${API_URL}/tasks/${task.id}`, {
        ...task,
        state: newState // Update task state on the server
      }, { headers: { Authorization: `Bearer ${token}` } });
  
      setLoading(false);
    } catch (err) {
      setError('Error updating task status');
      setLoading(false);
    }
  };
  
  const toggleTaskStatus = async (taskId, notStarted) => {
    try {
      setLoading(true);
  
      let description = '';
  
      if (notStarted) {
        // Starting the task
        description = prompt('Enter a description for this time interval:');
        if (!description) {
          alert('You must provide a description to start the task.');
          setLoading(false);
          return;
        }
      }
  
      // Make the POST request to start or finish the task
      if (notStarted) {
        // Start the task
        await axios.post(
          `${API_URL}/tasks/${taskId}/start`,
          { description },
          { headers: { Authorization: `Bearer ${token}` } }
        );
      } else {
        // Finish the task
        await axios.post(
          `${API_URL}/tasks/${taskId}/finish`,
          {},
          { headers: { Authorization: `Bearer ${token}` } }
        );
      }
  
      // Refresh the task data
      fetchData();
      setLoading(false);
    } catch (err) {
      console.error('Error toggling task status:', err.response ? err.response.data : err.message);
      if (err.response && err.response.status === 401) {
        setError('Unauthorized. Please log in again.');
        handleLogout(); // Optionally log the user out if the token is invalid
      } else {
        setError('Error toggling task status');
      }
      setLoading(false);
    }
  };
  
  
  // Log work on a task
  const logWork = async (taskId) => {
    const description = prompt('Enter work description:');
    const minutesLogged = parseFloat(prompt('Enter worked minutes:'));
    if (!description || isNaN(minutesLogged)) {
      alert('Invalid input. Please provide a description and valid minutes.');
      return;
    }
    try {
      setLoading(true);
      await axios.post(`${API_URL}/tasks/${taskId}/log`, { description, minutes_logged: minutesLogged }, { headers: { Authorization: `Bearer ${token}` } });
      alert('Work logged successfully');
      setLoading(false);
    } catch (err) {
      setError('Error logging work');
      setLoading(false);
    }
  };

  const formatTimeInHoursAndMinutes = (totalHours) => {
    const hours = Math.floor(totalHours); // Get the whole hours
    const minutes = Math.round((totalHours - hours) * 60); // Get the remaining minutes
    return `${hours} hours ${minutes} minutes`;
  };

  // Calculate total hours per project in the last month
  const calculateTotalHoursPerProject = () => {
    const hoursPerProject = projects.map((project) => {
      const projectTasks = tasks.filter((task) => task.project_id === project.id);
      const totalHours = projectTasks.reduce((sum, task) => {
        const logHours = task.direct_logs.reduce((taskSum, log) => taskSum + (log.minutes_logged / 60), 0); // Convert minutes to hours
        const totalTimeSpent = task.time_interval_logs.reduce((total, interval) => {
          return total + getTimeDifferenceInHours(interval.start_time, interval.end_time); // Using hours instead of seconds
        }, 0);
        return sum + logHours + totalTimeSpent;
      }, 0);
  
      // Ensure totalHours is at least 0 (in case there are no tasks or logs)
      return { projectName: project.name, totalHours: totalHours || 0 }; 
    });
  
    return hoursPerProject;
  };

  // Helper function to calculate time difference in hours
  const getTimeDifferenceInHours = (start, end) => {
    const startDate = new Date(start);
    const endDate = new Date(end);
    const timeInSeconds = (endDate - startDate) / 1000;
    return timeInSeconds / 3600; // Convert seconds to hours
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleViewChange = (newView) => {
    setView(newView);
    setAnchorEl(null);  // Close the menu after selection
    console.log("View changed to:", newView);  // Debugging log to verify the view is being set correctly
  };

  const handleProjectChange = (projectId, field, value) => {
    setEditedProjects((prevState) => ({
      ...prevState,
      [projectId]: {
        ...prevState[projectId],
        [field]: value,
      },
    }));
  };
  
  const handleSaveProject = async (projectId) => {
    // Fetch the original project data as a fallback if `editedProjects[projectId]` is undefined
    const originalProject = projects.find(p => p.id === projectId);
  
    if (!originalProject) {
      alert('Project not found');
      return;
    }
  
    // Merge edited values with the original project values
    const project = {
      name: editedProjects[projectId]?.name || originalProject.name, // Fallback to original name if not edited
      contact: editedProjects[projectId]?.contact || originalProject.contact, // Fallback to original contact
      hourly_rate: editedProjects[projectId]?.hourly_rate || originalProject.hourly_rate, // Fallback to original hourly rate
      description: editedProjects[projectId]?.description || originalProject.description, // Fallback to original description
      state: editedProjects[projectId]?.state || originalProject.state, // Fallback to original state
    };
  
    try {
      await axios.put(`${API_URL}/projects/${projectId}`, project, {
        headers: { Authorization: `Bearer ${token}` },
      });
      alert('Project updated successfully');
      fetchData(); // Re-fetch the data after successful save
    } catch (err) {
      alert('Error updating project');
    }
  };

  return (
    <Container>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" sx={{ flexGrow: 1 }}>
            Task and Project Manager
          </Typography>
          <Button color="inherit" onClick={handleMenuClick}>
            Menu
          </Button>
          <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
            <MenuItem onClick={() => handleViewChange('tasks')}>List and Add Tasks</MenuItem>
            <MenuItem onClick={() => handleViewChange('projects')}>Reports</MenuItem>
            <MenuItem onClick={() => handleViewChange('editProjects')}>Edit Projects</MenuItem> {/* New Edit Projects Menu Item */}
          </Menu>
          <Button color="inherit" onClick={handleLogout}>
            Logout
          </Button>
        </Toolbar>
      </AppBar>

      {error && <Typography color="error">{error}</Typography>}

      {view === 'tasks' && (
        // Tasks View
        <Box>
        <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpenTaskDialog(true)}
        style={{ marginTop: '20px' }}
        >
        Add Task
        </Button>

        {/* Add Project Button */}
        <Button
        variant="contained"
        color="primary"
        startIcon={<Add />}
        onClick={() => setOpenProjectDialog(true)}
        style={{ marginTop: '20px', marginLeft: '20px' }}
        >
        Add Project
        </Button>


        {/* Filter Section */}
        <Typography variant="h6" style={{ marginTop: '20px' }}>Filter Tasks</Typography>
        <FormControl fullWidth margin="normal" >
        <InputLabel>Project</InputLabel>
        <Select value={projectFilter} onChange={(e) => setProjectFilter(e.target.value)}> {/* Use projectFilter */}
            <MenuItem value="">All Projects</MenuItem>
            {projects.map((project) => (
            <MenuItem key={project.id} value={project.id}>
                {project.name}
            </MenuItem>
            ))}
        </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
        <InputLabel>Assignee</InputLabel>
        <Select value={assigneeFilter} onChange={(e) => setAssigneeFilter(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {USERS.map((assignee) => (
            <MenuItem key={assignee} value={assignee}>
                {assignee}
            </MenuItem>
            ))}
        </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
        <InputLabel>Status</InputLabel>
        <Select value={statusFilter} onChange={(e) => setStatusFilter(e.target.value)}>
            <MenuItem value="">All</MenuItem>
            {['todo', 'in progress', 'done'].map((status) => (
            <MenuItem key={status} value={status}>
                {status}
            </MenuItem>
            ))}
        </Select>
        </FormControl>

        {/* Display Tasks */}
        <Typography variant="h6" style={{ marginTop: '20px' }}>Tasks</Typography>
                  <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    Task Name
                    <IconButton onClick={() => sortTasks('name')}>
                      {sortConfig.key === 'name' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Project
                    <IconButton onClick={() => sortTasks('project_name')}>
                      {sortConfig.key === 'project_name' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Assignee
                    <IconButton onClick={() => sortTasks('assignee')}>
                      {sortConfig.key === 'assignee' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Reporter
                    <IconButton onClick={() => sortTasks('reporter')}>
                      {sortConfig.key === 'reporter' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>
                    Status
                    <IconButton onClick={() => sortTasks('state')}>
                      {sortConfig.key === 'state' && sortConfig.direction === 'asc' ? (
                        <ArrowUpward />
                      ) : (
                        <ArrowDownward />
                      )}
                    </IconButton>
                  </TableCell>
                  <TableCell>Actions</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {tasks
                  .filter(
                    (task) =>
                      (assigneeFilter === '' || task.assignee === assigneeFilter) &&
                      (statusFilter === '' || task.state === statusFilter) &&
                      (projectFilter === '' || task.project_id === projectFilter)
                  )
                  .map((task) => (
                    <React.Fragment key={task.id}>
                      <TableRow>
                        <TableCell>{task.name}</TableCell>
                        <TableCell>{task.project_name}</TableCell>
                        <TableCell>{task.assignee}</TableCell>
                        <TableCell>{task.reporter}</TableCell>
                        <TableCell>
                          <FormControl>
                            <Select
                              value={task.state}
                              onChange={(e) => toggleStatus(task, e.target.value)}
                              displayEmpty
                            >
                              <MenuItem value="todo">To-do</MenuItem>
                              <MenuItem value="in progress">In Progress</MenuItem>
                              <MenuItem value="done">Done</MenuItem>
                            </Select>
                          </FormControl>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() =>
                              toggleTaskStatus(
                                task.id,
                                task.time_interval_logs.length === 0 ||
                                  task.time_interval_logs[task.time_interval_logs.length - 1].end_time != null
                              )
                            }
                            color="primary"
                          >
                            {task.time_interval_logs.length === 0 ||
                            task.time_interval_logs[task.time_interval_logs.length - 1].end_time != null ? (
                              <PlayArrow />
                            ) : (
                              <Pause />
                            )}
                          </IconButton>
                          <IconButton onClick={() => logWork(task.id)} color="primary">
                            <Watch />
                          </IconButton>
                          <IconButton onClick={() => handleEditTask(task)} color="primary">
                            <Edit />
                          </IconButton>
                        </TableCell>
                        <TableCell>
                          <IconButton onClick={() => handleExpandClick(task.id)}>
                            {expandedTask === task.id ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                          <Collapse in={expandedTask === task.id} timeout="auto" unmountOnExit>
                            <Box margin={2}>
                            <Box display="flex" justifyContent="space-between" alignItems="center">
                              <Typography variant="subtitle1">Time Interval Logs</Typography>
                              <Button
                                variant="contained"
                                color="primary"
                                style={{ marginLeft: 'auto' }}
                                onClick={exportToExcel}
                              >
                                Export Logs to Excel
                              </Button>
                            </Box>
                              {task.time_interval_logs.length === 0 ? (
                                <Typography>No time interval logs</Typography>
                              ) : (
                                <ul>
                                  {task.time_interval_logs.map((log, index) => (
                                    <li key={log.id || index}>
                                      <Typography>
                                        <strong>Start:</strong> {new Date(log.start_time).toLocaleString()},
                                        <strong> End:</strong> {log.end_time ? new Date(log.end_time).toLocaleString() : 'In Progress'}
                                      </Typography>
                                      {log.description && (
                                        <Typography>
                                          <strong>Description:</strong> {log.description}
                                        </Typography>
                                      )}
                                      {/* Edit and Delete Buttons */}
                                      <IconButton onClick={() => handleEditTimeIntervalLog(task.id, log)} color="primary">
                                        <Edit />
                                      </IconButton>
                                      <IconButton onClick={() => handleDeleteTimeIntervalLog(task.id, log.id)} color="secondary">
                                        <Delete />
                                      </IconButton>
                                    </li>
                                  ))}
                                </ul>
                              )}

                              {/* Direct Logs */}
                              <Typography variant="subtitle1" style={{ marginTop: '20px' }}>Direct Logs</Typography>
                              {task.direct_logs.length === 0 ? (
                                <Typography>No direct logs</Typography>
                              ) : (
                                <ul>
                                  {task.direct_logs.map((log, index) => (
                                    <li key={log.id || index}>
                                      <Typography>
                                        <strong>Description:</strong> {log.description}, 
                                        <strong> Minutes:</strong> {log.minutes_logged}
                                      </Typography>
                                      {/* Edit and Delete Buttons */}
                                      <IconButton onClick={() => handleEditDirectLog(task.id, log)} color="primary">
                                        <Edit />
                                      </IconButton>
                                      <IconButton onClick={() => handleDeleteDirectLog(task.id, log.id)} color="secondary">
                                        <Delete />
                                      </IconButton>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </Box>

                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>

        {/* Dialog for Adding Task */}
        <Dialog open={openTaskDialog} onClose={() => setOpenTaskDialog(false)}>
        <DialogTitle>Add Task</DialogTitle>
        <DialogContent>
            <DialogContentText>Fill in the task details below.</DialogContentText>
            <FormControl fullWidth margin="normal">
            <InputLabel>Project</InputLabel>
            <Select value={projectId} onChange={(e) => setProjectId(e.target.value)}>
                {projects.map((project) => (
                <MenuItem key={project.id} value={project.id}>
                    {project.name}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
            <TextField
            label="Task Name"
            value={taskName}
            onChange={(e) => setTaskName(e.target.value)}
            fullWidth
            margin="normal"
            />
            {/* <TextField
            label="Assignee"
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            fullWidth
            margin="normal"
            /> */}

            <FormControl fullWidth margin="normal">
            <InputLabel>Assignee</InputLabel>
            <Select value={assignee} onChange={(e) => setAssignee(e.target.value)}>
                {USERS.map((name) => (
                <MenuItem key={name} value={name}>
                    {name}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
            <InputLabel>Reporter</InputLabel>
            <Select value={reporter} onChange={(e) => setReporter(e.target.value)}>
                {USERS.map((name) => (
                <MenuItem key={name} value={name}>
                    {name}
                </MenuItem>
                ))}
            </Select>
            </FormControl>
            <TextField
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            fullWidth
            margin="normal"
            multiline
            rows={3}
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenTaskDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={createTask} color="primary">Create Task</Button>
        </DialogActions>
        </Dialog>

        {/* Dialog for Adding Project */}
        <Dialog open={openProjectDialog} onClose={() => setOpenProjectDialog(false)}>
        <DialogTitle>Add Project</DialogTitle>
        <DialogContent>
            <DialogContentText>Fill in the project details below.</DialogContentText>
            <TextField
            label="Project Name"
            value={projectName || ''}
            onChange={(e) => setProjectName(e.target.value)}
            fullWidth
            margin="normal"
            />
            <TextField
            label="Project Contact"
            value={projectContact || ''}
            onChange={(e) => setProjectContact(e.target.value)}
            fullWidth
            margin="normal"
            />
            <TextField
            label="Hourly Rate (HUF)"
            value={hourlyRate || ''}
            onChange={(e) => setHourlyRate(e.target.value)}
            fullWidth
            margin="normal"
            type="number"
            />
        </DialogContent>
        <DialogActions>
            <Button onClick={() => setOpenProjectDialog(false)} color="secondary">Cancel</Button>
            <Button onClick={createProject} color="primary">Create Project</Button>
        </DialogActions>
        </Dialog>

            <Dialog open={openEditTaskDialog} onClose={() => setOpenEditTaskDialog(false)}>
            <DialogTitle>Edit Task</DialogTitle>
            <DialogContent>
                <DialogContentText>Modify the task details below.</DialogContentText>
                <FormControl fullWidth margin="normal">
                    <InputLabel>Project</InputLabel>
                    <Select value={taskToEdit?.project_id || ''} onChange={(e) => setTaskToEdit({ ...taskToEdit, project_id: e.target.value })}>
                    {projects.map((project) => (
                        <MenuItem key={project.id} value={project.id}>
                        {project.name} {/* Show/Select Project Name in Edit Task Dialog */}
                        </MenuItem>
                    ))}
                    </Select>
                </FormControl>
                <TextField
                    label="Task Name"
                    value={taskToEdit?.name || ''}
                    onChange={(e) => setTaskToEdit({ ...taskToEdit, name: e.target.value })}
                    fullWidth
                    margin="normal"
                />
                <TextField
                    label="Description"
                    value={taskToEdit?.description || ''}
                    onChange={(e) => setTaskToEdit({ ...taskToEdit, description: e.target.value })}
                    fullWidth
                    margin="normal"
                    multiline
                    rows={3}
                />
                {/* Add any other fields you want to edit here */}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpenEditTaskDialog(false)} color="secondary">Cancel</Button>
                <Button onClick={updateTask} color="primary">Save Changes</Button>
            </DialogActions>
        </Dialog>

        {/* Dialog for Editing Time Interval Log */}
        <Dialog
            open={openEditTimeIntervalLogDialog}
            onClose={() => setOpenEditTimeIntervalLogDialog(false)}
          >
            <DialogTitle>Edit Time Interval Log</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Modify the time interval log details below.
              </DialogContentText>
              <TextField
                label="Start Time"
                type="datetime-local"
                value={
                  logToEdit && logToEdit.start_time
                    ? new Date(logToEdit.start_time).toISOString().slice(0, -1)
                    : ''
                }
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, start_time: e.target.value })
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="End Time"
                type="datetime-local"
                value={
                  logToEdit && logToEdit.end_time
                    ? new Date(logToEdit.end_time).toISOString().slice(0, -1)
                    : ''
                }
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, end_time: e.target.value })
                }
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
              />
              <TextField
                label="Description"
                value={logToEdit ? logToEdit.description : ''}
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, description: e.target.value })
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditTimeIntervalLogDialog(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={updateTimeIntervalLog} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

          {/* Dialog for Editing Direct Log */}
          <Dialog
            open={openEditDirectLogDialog}
            onClose={() => setOpenEditDirectLogDialog(false)}
          >
            <DialogTitle>Edit Direct Log</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Modify the direct log details below.
              </DialogContentText>
              <TextField
                label="Description"
                value={logToEdit ? logToEdit.description : ''}
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, description: e.target.value })
                }
                fullWidth
                margin="normal"
                multiline
                rows={3}
              />
              <TextField
                label="Minutes Logged"
                type="number"
                value={logToEdit ? logToEdit.minutes_logged : ''}
                onChange={(e) =>
                  setLogToEdit({ ...logToEdit, minutes_logged: e.target.value })
                }
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setOpenEditDirectLogDialog(false)} color="secondary">
                Cancel
              </Button>
              <Button onClick={updateDirectLog} color="primary">
                Save Changes
              </Button>
            </DialogActions>
          </Dialog>

        </Box>
       )}
      {view === 'projects' && (
          <Box>
            {/* Display total hours per project */}
            <Typography variant="h6" style={{ marginTop: '20px' }}>Total minutes per Project (Last Month)</Typography>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Total Time Spent</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {calculateTotalHoursPerProject().map((project) => (
                    <TableRow key={project.projectName}>
                      <TableCell>{project.projectName}</TableCell>
                      <TableCell>
                        {typeof project.totalHours === 'number'
                          ? formatTimeInHoursAndMinutes(project.totalHours)
                          : '0 hours 0 minutes'}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
      )}

      {/* Edit projects view */}
      {view === 'editProjects' ? (
        <Box>
            <Typography variant="h5" gutterBottom>
            Edit Projects
            </Typography>

            <TableContainer component={Paper}>
            <Table>
                <TableHead>
                <TableRow>
                    <TableCell>Project Name</TableCell>
                    <TableCell>Hourly Rate (HUF)</TableCell>
                    <TableCell>Contact</TableCell> {/* New Contact Column */}
                    <TableCell>Status</TableCell> {/* New Status Column */}
                    <TableCell>Description</TableCell>
                    <TableCell>Actions</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                {projects.map((project) => (
                    <TableRow key={project.id}> {/* Ensure a unique key */}
                    <TableCell>
                        <TextField
                        value={editedProjects[project.id]?.name || project.name}
                        onChange={(e) => handleProjectChange(project.id, 'name', e.target.value)}
                        fullWidth
                        />
                    </TableCell>
                    <TableCell>
                        <TextField
                        value={editedProjects[project.id]?.hourly_rate || project.hourly_rate}
                        onChange={(e) => handleProjectChange(project.id, 'hourly_rate', e.target.value)}
                        type="number"
                        fullWidth
                        />
                    </TableCell>
                    <TableCell> {/* New Contact Field */}
                        <TextField
                        value={editedProjects[project.id]?.contact || project.contact}
                        onChange={(e) => handleProjectChange(project.id, 'contact', e.target.value)}
                        fullWidth
                        />
                    </TableCell>
                    <TableCell> {/* New Status Dropdown */}
                        <FormControl fullWidth>
                        <InputLabel>Status</InputLabel>
                        <Select
                            value={editedProjects[project.id]?.state || project.state}
                            onChange={(e) => handleProjectChange(project.id, 'state', e.target.value)}
                        >
                            <MenuItem value="in progress">In Progress</MenuItem>
                            <MenuItem value="done">Done</MenuItem>
                        </Select>
                        </FormControl>
                    </TableCell>
                    <TableCell>
                        <TextField
                        value={editedProjects[project.id]?.description || project.description}
                        onChange={(e) => handleProjectChange(project.id, 'description', e.target.value)}
                        fullWidth
                        multiline
                        rows={3}
                        />
                    </TableCell>
                    <TableCell>
                        <Button
                        variant="contained"
                        color="primary"
                        onClick={() => handleSaveProject(project.id)}
                        >
                        Save
                        </Button>
                    </TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
            </TableContainer>
        </Box>
        ) : null}
    </Container>
  );
}

export default TaskManager;
